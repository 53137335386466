import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import store from 'store'
import { Provider } from 'react-redux'
import { AnimatePresence} from 'framer-motion'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {Header, Footer} from 'components/shared'
import {VerifyRequest, UploadRecords, ThankYou, Confirmation} from 'components/ui';
import {Home, Faq, Terms, Privacy, Compliance, Security, RecordsUpload} from 'components/static'

Sentry.init({
  dsn: "https://8f0aa7e1b5f441e2a8e6dacd81bf52f6@o819500.ingest.sentry.io/5808807",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <BrowserRouter>
    <div className="siteWrapper">
      <Header />
        <div className="main_wrapper"> 
          <Provider store={store()}>
            <AnimatePresence>
              <Switch>
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/compliance" component={Compliance} />
                <Route exact path="/security" component={Security} />
                <Route exact path="/records-upload" component={RecordsUpload} />
                <Route exact path="/verify-request" component={VerifyRequest} />
                <Route exact path="/upload-records" component={UploadRecords} />
                <Route exact path="/thank-you" component={ThankYou} />
                <Route exact path="/confirmation" component={Confirmation} />
                <Route render={() => <Redirect to={{pathname: "/home"}} />} />
              </Switch>
            </AnimatePresence>
          </Provider>
        </div>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

// @ts-ignore
export default App;
// @ts-ignore