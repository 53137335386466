import { useState, useEffect, useRef, useCallback } from 'react';

export const useFakeProgress = () => {
  const timer = useRef<NodeJS.Timeout>();
  const [progress, setProgress] = useState(0);

  const clean = useCallback(() => {
    if (timer.current) {
      setProgress(0);
      clearInterval(timer.current);
      timer.current = undefined;
    }
  }, []);

  useEffect(() => {
    return () => {
      clean();
    };
  }, [clean]);

  const start = useCallback(() => {
    if (timer.current) clean();
    let current_progress = 0;
    const step = 1;
    timer.current = setInterval(() => {
      current_progress += step;
      setProgress(current_progress);
      if (progress >= 100 && timer.current) {
        clearInterval(timer.current);
      }
    }, 30);
  }, [progress, clean]);

  const done = useCallback(() => {
    setProgress(100);
    setTimeout(clean, 100);
  }, [clean]);

  return { progress, start, clean, done };
};
