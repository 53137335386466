export interface RequestId {
    id: number;
}
export interface RequestDetails {
    firstName: string;
    lastName: string;
    providerName: string;
    recordsBucketList: []
}

export const REQUEST_LOADING = 'REQUEST_LOADING' as const;
export const REQUEST_LOADED = 'REQUEST_LOADED' as const;
export const REQUEST_LOADING_FAILED = 'REQUEST_LOADING_FAILED' as const;
