import React from 'react';
import ContentImage_4 from 'assets/images/ContentImage_4.png';
import ContentImage_3 from 'assets/images/ContentImage_3.png';
import ContentImage_2 from 'assets/images/ContentImage_2.png';
import ContentImage_1 from 'assets/images/ContentImage_1.png';
import Banner_image from 'assets/images/Banner_image.png';
import YourPrivateInfo_icon_1 from 'assets/images/YourPrivateInfo_icon_1.png';
import YourPrivateInfo_icon_2 from 'assets/images/YourPrivateInfo_icon_2.png';
import YourPrivateInfo_icon_3 from 'assets/images/YourPrivateInfo_icon_3.png';
import YourPrivateInfo_icon_4 from 'assets/images/YourPrivateInfo_icon_4.png';
import HowItAllWorks_icon_1 from 'assets/images/HowItAllWorks_icon_1.png';
import HowItAllWorks_icon_2 from 'assets/images/HowItAllWorks_icon_2.png';
import HowItAllWorks_icon_3 from 'assets/images/HowItAllWorks_icon_3.png';
import { Icon } from 'react-icons-kit';
// import { ic_trending_flat } from 'react-icons-kit/md/ic_trending_flat';
import { ic_description } from 'react-icons-kit/md/ic_description';
import { ic_verified_user } from 'react-icons-kit/md/ic_verified_user';
import { ic_ac_unit } from 'react-icons-kit/md/ic_ac_unit'
import {motion} from "framer-motion";
import {variants} from "store/types/variants.types";

export const Home = () => {
  return (
    <motion.div initial='out' animate='in' exit='out' variants={variants}>
      <div className="key_medical_wrapper">
   
        <div className="container">
          <div className="row">
            <div className="banner_left col-xs-12 col-sm-6">
              <h2 className="montserrat">
                All your medical records in one place.
              </h2>
              {/*<h3>Where do you need medical records from?</h3>*/}
              {/*<div className="key_search_box">*/}
              {/*  <span className="search_box">*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      placeholder="Search hospital or doctor's practice"*/}
              {/*    />*/}
              {/*  </span>*/}
              {/*  <span className="get_search">Get Your Records</span>*/}
              {/*</div>*/}
            </div>
            <div className="banner_right col-xs-12 col-sm-6">
              <div className="banner_img_wrapper">
                <img src={Banner_image} className="img-responsive" alt="banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="key_medical_for_wrapper">
              <h2 className="montserrat">KeyMedical is for</h2>
              <div className="key_med_patients">
                <div className="key_med_txt montserrat">Patients</div>
                <p>Instant, simple access to full medical history</p>
              </div>
              <div className="key_med_providers montserrat">
                <div className="key_med_txt">Providers</div>
                <p>
                  Secure transfer of records and compliance with ONC's Info
                  Blocking Rule (Effective on April 5, 2020)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">

          <div className="key_consolidate_wrapper">
            <div className="key_custom_left">
              <h2 className="montserrat">
                Your Key Medical Records. Gathered auto-magically.
                </h2>
              <p>
                Consolidate your medical records in one place and leverage Key
                Medical Records to automate medical record collection from
                everywhere that you have received care.
                </p>
              <div className="key_action_btm">
                <span className="key_get_rec">Learn more</span>
              </div>
            </div>
            <div className="key_custom_right">
              <img
                src={ContentImage_1}
                className="img-responsive"
                alt="content 2"
              />
            </div>
          </div>

        </div>
        <div className="container">
          <div className="row">
            <div className="key_manage_fam_wrapper">
              <div className="key_custom_left">
                <img
                  src={ContentImage_2}
                  className="img-responsive"
                  alt="content 2"
                />
              </div>
              <div className="key_medical_right">
                <h2 className="montserrat">
                  Manage your whole family in one place
                </h2>
                <p>
                  Different portals, different logins? No problem. Gather all
                  your family’s patient portal health information into one
                  organized, convenient place
                </p>
                <div className="key_action_btm">
                  <span className="key_get_rec">Get Your Records</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec_main_wrapper">
          <div className="container">
            <div className="row">
              <div className="key_sec_provider  text-center">
                <h2>
                  Secure Provider to Provider Records Transfer = Better Patient
                Care{" "}
                </h2>
                <p>
                  Wherever they are, whatever system they’re in, we have a way to
                  get your health records and put them in one easily accessible,
                super-safe place – your Key Medical Record.{" "}
                </p>
              </div>
              <div className="sec_item_wrapper">
                <div className="secure_items">
                  <span className="sec_img"><Icon className="medical_item_icons" size={45} icon={ic_description} /></span>
                  <span className="sec_text medical">Medical</span>
                </div>
                <div className="secure_items">
                  <span className="sec_img"><Icon className="billing_item_icons" size={45} icon={ic_verified_user} /></span>
                  <span className="sec_text billing">Billing Records</span>
                </div>
                <div className="secure_items">
                  <span className="sec_img"><Icon className="dicom_item_icons" size={45} icon={ic_ac_unit} /></span>
                  <span className="sec_text dicom">DICOM Images</span>
                </div>
              </div>
              <div className="sec_constant_img">
                <img src={ContentImage_3} alt="content 3" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="key_work_wrapper">
              <h2 className="how_work_txt">How it all works </h2>
              <div className="key_hw_items">
                <div className="hw_img_wrapper">
                  <img
    src={HowItAllWorks_icon_1}
    className="img-responsive"
    alt="how it works 1"
    />
                </div>
                <div className="hw_item_txt">Sign up in minutes </div>
                <p>
                  Enter your doctor info, e-sign an authorization form, and
                  we'll take it from there.{" "}
                </p>
              </div>
              <div className="key_hw_items">
                <div className="hw_img_wrapper">
                  <img
    src={HowItAllWorks_icon_2}
    className="img-responsive"
    alt="how it works 2"
    />
                </div>
                <div className="hw_item_txt">We do the work</div>
                <p>
                  We'll handle all the phone calls and faxes to retrieve your
                  records.
                </p>
              </div>
              <div className="key_hw_items">
                <div className="hw_img_wrapper">
                  <img
    src={HowItAllWorks_icon_3}
    className="img-responsive"
    alt="how it works 3"
    />
                </div>
                <div className="hw_item_txt">Receive your timeline</div>
                <p>
                  No paperwork, no messy files. Just an entire digitized history
                  of your health.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="med_bil-dic_wrapper"/>

        <div className="key_pr_info_wrapper">
          <div className="container">
            <div className="row">
              <h2 className="key_pr_txt">Your private info stays private</h2>
              <p className="key_pr_help_txt">
                Health should never come at the expense of privacy. Your records
                are encrypted, protected, and under your control. You decide
                what you want to share.{" "}
              </p>
              <div className="key_pr_info_items">
                <div className="key_info-icons">
                  <img src={YourPrivateInfo_icon_1} alt="private info 1" />
                </div>
                <div className="key_info_text">
                  <h3>HIPAA compliant</h3>
                  <p>The highest standards of privacy set by law.</p>
                </div>
              </div>
              <div className="key_pr_info_items">
                <div className="key_info-icons">
                  <img src={YourPrivateInfo_icon_3} alt="private info 3" />
                </div>
                <div className="key_info_text">
                  <h3>End-to-end encryption </h3>
                  <p>
                    The same technology banks use to keep your information
                    secure.{" "}
                  </p>
                </div>
              </div>
              <div className="key_pr_info_items">
                <div className="key_info-icons">
                  <img src={YourPrivateInfo_icon_2} alt="private info 2" />
                </div>
                <div className="key_info_text">
                  <h3>Under your control </h3>
                  <p>
                    We'll never share your information without explicit
                    permission.{" "}
                  </p>
                </div>
              </div>
              <div className="key_pr_info_items">
                <div className="key_info-icons">
                  <img src={YourPrivateInfo_icon_4} alt="private info 4" />
                </div>
                <div className="key_info_text">
                  <h3>If in doubt, reach out </h3>
                  <p>
                    Question about our privacy practices? We're always here to
                    help.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="key_mobile_records">
          <div className="container">
            <div className="row">
              <div className="key-mob-rec_left">
                <img src={ContentImage_4} alt="contact 4" className="img-responsive" />
              </div>
              <div className="key-mob-rec_right">
                <h2>Mobile Records, Mobile Ready. </h2>
                <p>
                  Your health records at your fingertips - on any device. Each
                  app is hand-built to work as secure, fast, and simple as your
                  phone.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="own_it_wrapper">
          <div className="container">
            <div className="row">
              <div className="health_wrapper">
                <h2>It's your health. Own it </h2>
                <p>Start building your Key Medical Record today. </p>
                <div className="get_started">Get Started</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </motion.div>
    );
}
