import React from 'react'
import logo_r2_logo_forLight from 'assets/images/logo_r2_logo_forLight.png'
// import { Col, Row, Container, Form } from "react-bootstrap"
export const Footer = () => {
    return (
        <div className='footer__wrapper'>
            <div className="container footer_constant">
                <div className="row">
                    <div className="col-sm-5">
                        <div className="footer_logo">
                            <img src={logo_r2_logo_forLight} alt='footer logo' className="img-responsive"/>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="footer_items">
                            <div className="fot_txt">About</div>
                            <ul>
                                <li><a href="/faq">FAQs</a></li>
                                {/*<li>Our Story</li>*/}
                                {/*<li>The Mission</li>*/}
                                {/*<li>Resources</li>*/}
                            </ul>
                        </div>
                        <div className="footer_items">
                            <div className="fot_txt">Company</div>
                            <ul>
                                {/*<li>FAQs</li>*/}
                                <li><a href="/terms">Terms</a></li>
                                <li><a href="/privacy">Privacy</a></li>
                            </ul>
                        </div>
                        <div className="footer_items">
                            <div className="fot_txt">Providers</div>
                            <ul>
                                <li><a href="/records-upload">Upload Records</a></li>
                                <li><a href="/security">Security</a></li>
                                <li><a href="/compliance">Compliance</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer_btm_txt">
                        <p>&#169; 2021 KeyMedicalRecord</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
