import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { useFakeProgress } from 'components/shared';
import { Icon } from 'react-icons-kit';
import {timesCircle} from 'react-icons-kit/fa/timesCircle'
import {exclamationTriangle} from 'react-icons-kit/fa/exclamationTriangle'
import styles from 'assets/styles/scss/dropzone.module.scss';
import ReactTooltip from "react-tooltip";

export type DropZoneProps = {
  className?: string;
  onUpload?: (files: File[]) => void;
  onRemove?: (index: number) => void;
  accept?: string;
  maxSize?: number;
  maxFileCount?: number;
  selectedFiles?: File[];
  showEye?: boolean;
  name: string;
  fileErrors?: any
};

export const DropZone: React.FC<DropZoneProps> = ({ name, className, onUpload, accept, maxFileCount, onRemove, fileErrors, ...args }) => {
  if (!maxFileCount) maxFileCount = 1
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [error, setError] = useState('');
  const { progress, start, done, clean } = useFakeProgress();

  useEffect(() => {
    if (args.selectedFiles?.length) setSelectedFiles(args.selectedFiles)
  }, [args.selectedFiles])
  const upload = useCallback(
    (files: File[]) => {
      onUpload && onUpload(files);
      done();
    },
    [done, onUpload],
  );

  const onDrop = useCallback(
    files => {
      if (files.length) {
        if (error) setError('');
        let countLeft = (maxFileCount || 1) - selectedFiles.length
        let leftFiles = files.slice(0, countLeft)
        upload(leftFiles);
        start();
      }
    },
    [error, maxFileCount, selectedFiles.length, upload, start],
  );

  const onDropRejected = useCallback(
    (files) => {
      if (files.length) {
        clean();
        let maxSize = args.maxSize ? args.maxSize/(1024*1024) : 5
        if (files[0]?.errors[0]?.code === 'file-invalid-type') setError('Only .pdf files are allowed to upload.');
        else setError(`<span>${files[0].file.name}</span> is too large. Maximum ${maxSize} mb allowed for upload.`);
      }
    },
    [args.maxSize, clean],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: args.maxSize || 5000000,
    multiple: maxFileCount > 1,
    onDropRejected,
    accept,
  });

  const onFileRemove = (index: number) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    onRemove && onRemove(index)
  };

  const onEyeClick = (file: File) => {
    let a = document.createElement('a')
    a.href = URL.createObjectURL(file)
    a.target = '_blank'
    a.click()
  }

  return (
    <div className={clsx(styles.root, className, name)}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadZone name={name} className={className} />
      </div>
      {(selectedFiles.length > 0)&&(
      <div className="drop_file_container">
        {selectedFiles.map((file, i) => (
          <UploadItem key={name+i} name={name} length={selectedFiles.length} index={i} onReset={() => onFileRemove(i)} percent={progress} onEyeClick={() => onEyeClick(file)} showEye={args.showEye} fileErrors={fileErrors}>
            {file.name}
          </UploadItem>
        ))}
      </div>)}
      {error && <ErrorBlock text={error} />}
    </div>
  );
};

const UploadZone: React.FC<any> = ({ name, className }) => (
  <div className={clsx(styles.upload, className, name)} key={name}>
    <p className="fileheading">{name.replace(/_/g, " ")}</p>
    <img src='/cloud_upload.png' alt={name} className='dropzone-image'/>
    Drag & Drop<div className={clsx(styles.browse, className, name)}>or Browse</div>
  </div>
);

type ErrorBlockProps = {
  text: string;
};

const ErrorBlock: React.FC<ErrorBlockProps> = ({ text }) => (
  <div className={styles.error} dangerouslySetInnerHTML={{ __html: text }} />
);

type UploadItemProps = {
  name: string,
  percent: number;
  onReset: () => void;
  showEye?: boolean;
  onEyeClick?: () => void;
  index: number;
  fileErrors: string;
  length: number;
};

const UploadItem: React.FC<UploadItemProps> = ({ name, index, length, children, onReset, fileErrors }) => {
  return (
      <div>
        <div className={clsx(styles['upload-item'], 'mb-2', name, fileErrors[name]? (fileErrors[name][index]? 'errorFile dropzone-upload-item' : 'dropzone-upload-item') : 'dropzone-upload-item')} >
          {(fileErrors[name]? fileErrors[name][index]:undefined) &&
            <div className="danger_icons">
              <Icon className="left_icons" size={20} icon={exclamationTriangle} data-tip={fileErrors[name][index]}/>
              <ReactTooltip place="top" type="info" effect="solid"/>
            </div>
          }
          {children}
          <div className="delete_icons">
            <Icon className="right_icons" size={20} icon={timesCircle}  onClick={onReset} />
          </div>
        </div>
        {(index < (length - 1)) &&
          <div className="horizontal-line"/>
        }
      </div>
  );
};