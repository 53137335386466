import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import {requestSelector} from 'store/reducers/request.reducer'
import * as RequestAction from 'store/actions/request.action'
import {Loader,ResponseError} from 'components/shared';
import { Icon } from 'react-icons-kit';
import {ic_done} from 'react-icons-kit/md/ic_done'
import {motion} from "framer-motion";
import {variants} from "store/types/variants.types";

export const Confirmation = () => {
    const {requestDetails, isLoading: isRequestLoading} = useSelector(requestSelector);
    const [responseError, setResponseError] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    if(!(requestDetails?.request_id)){
        history.push("/verify-request");
    }
    const callThankYouPage = () => {
        history.push("/thank-you");
    }
    const onSubmit = ()=> {
        let formdata = new FormData();
        formdata.append('request_id', requestDetails.request_id);
        dispatch(RequestAction.MoveRequestToPrice(requestDetails, formdata, {}, (response:any, err: any)=>{
            if(err){
                if(err.data){
                    if(err.data.message){
                        setResponseError(err.data.message);
                    }else if(err.data.errors) {
                        setResponseError(err.data.errors);
                    }
                }else{
                    setResponseError("Something went wrong");
                }
            }else{
                callThankYouPage()
            }
        }));
    }
    return (
        <div>
            <motion.div className="upload-medical-wrapper" initial='out' animate='in' exit='out' variants={variants}>
                <div className="upload_constant">
                    <div className="upload_img">
                        <span className="check_icons"><Icon className="right_icons" size={60} icon={ic_done} /></span>
                    </div>
                    <p className="up_txt">Thanks! The files are uploaded</p>
                    <p className="up-help-txt">
                        Do you need to upload any additional files for this request?
                    </p>
                    {responseError && <ResponseError error={responseError}/>}
                    <div className="up_act_btn">
                        <div className='col-6 confirmationBtns'>
                            <button type='button' className="app_btn"
                                    onClick={() => {onSubmit()}}>Yes, Upload Additional Files.</button>
                        </div>
                    </div>
                    <div className="up_act_btn">
                        <div className='col-6 confirmationBtns'>
                            <button type='button' className="app_btn cancelBtn"
                                    onClick={() => {callThankYouPage()}}>No, File Upload is Complete</button>
                        </div>
                    </div>
                </div>
            </motion.div>
            {isRequestLoading && <Loader />}
        </div>
    )
}
