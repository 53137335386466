import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import {requestSelector} from 'store/reducers/request.reducer'
import * as RequestAction from 'store/actions/request.action'
import {Loader, DropZone,ResponseError} from 'components/shared';
import { Icon } from 'react-icons-kit';
import {ic_done} from 'react-icons-kit/md/ic_done'
import {motion} from "framer-motion";
import {variants} from "store/types/variants.types";

export const UploadRecords = () => {
    const {requestDetails, isLoading: isRequestLoading} = useSelector(requestSelector);
    const [responseError, setResponseError] = useState("");
    const [responseFilesError, setResponseFilesErrorError] = useState({});
    const [filesCount, setFilesCount] = useState<number>(0);
    const [filesData, setFilesData] = useState<any>({});
    const history = useHistory();
    const dispatch = useDispatch();
    const onSubmit =()=> {
        let formdata = new FormData();
        setResponseError('');
        setResponseFilesErrorError('');
        formdata.append('request_id', requestDetails.request_id);
        let filesCount = 0;
        Object.keys(filesData).map((key) => (filesData[key]?.forEach((file, j) =>{
            filesCount += 1;
            formdata.append(`records[${key}][${j}][record]`, file);
        })));
        if(filesCount > 20){
            setResponseError("You can upload upto 20 files at a time.");
        }
        dispatch(RequestAction.UploadRecords(requestDetails, formdata, {headers: {
            'content-type': 'multipart/form-data'
          }}, (response:any, err: any)=>{
            if(err){
                if(err.data.message){
                    setResponseError(err.data.message);
                    setResponseFilesErrorError('');
                }else if(err.data.errors) {
                    setResponseFilesErrorError(err.data.errors);
                }
            }else{
                history.push("/confirmation");
            }
        }));
    };
    if(!(requestDetails?.request_id)){
        history.push("/verify-request");
    }
    const handleFileUpload = (files: File[], name:any) => {
        setResponseFilesErrorError("")
        setFilesCount(filesCount+files.length)
        setFilesData((filesData:any) => ({
            ...filesData, [name]: (filesData[name]||[]).concat(files)
        }))
    }
    const handleFileRemove = (name:any, index:number) => {
        setResponseFilesErrorError("")
        setFilesCount(filesCount-1)
        setFilesData((filesData:any) => ({
            ...filesData, [name]:filesData[name]?.filter((_, i) => i !== index)
        }))
    }
    return(
        <div>
            <motion.div className="upload-medical-wrapper custom_width" initial='out' animate='in' exit='out' variants={variants}>
                <div className="upload_constant">
                    <div className="upload_img">
                        <span className="check_icons"><Icon className="right_icons" size={60} icon={ic_done} /></span>
                    </div>
                    <p className="up_txt">Upload the Requested Records</p>
                    <p className="up-help-txt">You're sending records for
                        <strong className='text-capitalize'> {requestDetails?.patient_first_name}</strong>
                        <strong className='text-capitalize'> {requestDetails?.patient_last_name}.</strong> to
                        <strong className='text-capitalize'> {requestDetails?.provider_name}.</strong>
                    </p>
                    {responseError && <ResponseError error={responseError}/>}
                    <div className="drop_file_wrapper">
                        {requestDetails?.upload_records_bucket?.map((name, i) => {
                            return(<DropZone key={name+i} className="upload_items"
                                             name={name}
                                             selectedFiles={filesData[name]||[]}
                                             onUpload={files => handleFileUpload(files, name)}
                                             onRemove={j => handleFileRemove(name, j)}
                                             maxFileCount={3}
                                             maxSize={20 * 1024 * 1024}
                                             accept={(name==='Images') ? 'image/jpeg, image/png, image/jpeg': '.pdf'}
                                             fileErrors={responseFilesError} />)
                        })}
                    </div>
                    <div className="up_act_btn">
                        <button type='button' className="app_btn"
                        onClick={() => {onSubmit()}} disabled={filesCount < 1}>SUBMIT</button>
                    </div>
                </div>
            </motion.div>
            {isRequestLoading && <Loader />}
        </div>
    )
}
