import { api, paths } from '../apis'
import * as ActionTypes from 'store/types/request.types'

const requestLoading = () => ({
    type: ActionTypes.REQUEST_LOADING
})
const requestLoaded = (response: any) => ({
    type: ActionTypes.REQUEST_LOADED,
    payload: response
})
const requestLoadingFailed = (response: any, err: string) => ({
    type: ActionTypes.REQUEST_LOADING_FAILED,
    payload: response,
    err
})

export type Actions = 
| ReturnType<typeof requestLoading>
| ReturnType<typeof requestLoaded>
| ReturnType<typeof requestLoadingFailed>

export const VerifyRequest = (requestId: string, captchaValue: string, callback?: (res:any, err:any) => void): any => dispatch => {
    dispatch(requestLoading());
    api.get(paths.verifyrequest, {params: {request_id: requestId, captcha_response: captchaValue}})(dispatch)
    .then(res => {
        dispatch(requestLoaded(res));
        callback&&callback(res, null);
    })
    .catch(err => {
        if(!err){ err = {data: {message: "No response from server."}, message: "No response from server."}}
        dispatch(requestLoadingFailed({request_id: requestId}, err.message))
        callback&&callback(null, err);
    })
}

export const UploadRecords = (requestDetails: any, formData: any, config: any, callback?: (res:any, err: any) => void): any => dispatch => {
    dispatch(requestLoading());
    api.post(paths.upload_records, formData, config)(dispatch)
    .then(res => {
        dispatch(requestLoaded(res));
        callback&&callback(res, null);
    })
    .catch(err => {
        if(!err){ err = {data: {message: "No response from server."}, message: "No response from server."}}
        dispatch(requestLoadingFailed(requestDetails, err.message))
        callback&&callback(null, err);
    })
}

export const MoveRequestToPrice = (requestDetails: any, formData: any, config: any, callback?: (res:any, err: any) => void): any => dispatch => {
    dispatch(requestLoading());
    api.put(paths.move_request_to_priced, formData, config)(dispatch)
        .then(res => {
            dispatch(requestLoaded(res));
            callback&&callback(res, null);
        })
        .catch(err => {
            if(!err){ err = {data: {message: "No response from server."}, message: "No response from server."}}
            dispatch(requestLoadingFailed(requestDetails, err.message))
            callback&&callback(null, err);
        })
}
