import React from 'react';
import { useHistory } from "react-router-dom";
import logo_r2_logo_forDark from 'assets/images/logo_r2_logo_forDark.png';

export const Header = () => {
    const history = useHistory();
    const toHome = () => {
        history.push("/home");
    }
    const handleClick = () =>{
        history.push("/verify-request")
    }
    return (
        <div>
            <div className="head_section">
                <div className="container">
                    <div className="row">
                        <div className="top_head">
                            <div className="top_head_left" onClick={() => {toHome()}}>
                                <img src={logo_r2_logo_forDark} className="img-responsive" alt="test" />
                            </div>
                            <div className="top_head_right">
                                {/*<span className="rightmenu key_upload_code montserrat">Enter Upload Code</span>*/}
                                <span className="rightmenu key_upload_record montserrat" onClick={handleClick}>Upload Records</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
