import React from 'react'
import { Icon } from 'react-icons-kit';
import {ic_info} from 'react-icons-kit/md/ic_info'

export const ResponseError = ({error}) => {
    return (
        <div className="error_help_container">
            <div className="error_icons">
                <Icon className="info_error_iocns" size={50} icon={ic_info} />
            </div>
            <div className="error_help_txt">
                <span className="error_txt2">{error}</span>
            </div>
        </div>
    )
}