import React from 'react';

export const RecordsUpload = () => {
    return (
        <div className="bg_white">
          <div className="container">
            <h1 className="faq_tittle">Upload Records</h1>
            <div className="wrapper">
              Lorem Ipsum dolor
            </div>
          </div>
        </div>
    )
}
