import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
// @ts-ignore
import YourPrivateInfo_icon_3 from 'assets/images/YourPrivateInfo_icon_3.png';
import * as RequestAction from 'store/actions/request.action'
import { Loader, ResponseError } from 'components/shared';
import { requestSelector } from 'store/reducers/request.reducer';
import {motion} from "framer-motion";
import {variants} from "store/types/variants.types";
import ReCAPTCHA from "react-google-recaptcha";

export const VerifyRequest = () => {
    const [requestID, setRequestID] = useState("");
    const [responseError, setResponseError] = useState("");
    useEffect(() => {}, [requestID]);
    const history = useHistory();
    const dispatch = useDispatch()
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const onRecaptchaChange = () => {
        console.log("captcha changed");
        setApiCount(0);
    }
    const [apiCount, setApiCount] = useState(0)
    const onSubmit = ()=> {
        const recaptchaValue = recaptchaRef?.current?.getValue();
        if(!recaptchaValue){
            setResponseError("Please fill captcha.");
            return;
        }
        if(requestID.length < 1){
            setResponseError("Enter atleast a single character");
        }
        dispatch(RequestAction.VerifyRequest(requestID, recaptchaValue, (response:any, err:any)=>{
            if(err){
                if(apiCount<2 && err.data.message==="Invalid Captcha"){
                    setApiCount(apiCount+1);
                    onSubmit();
                    return
                }
                setResponseError(err.data.message);
                recaptchaRef.current.reset()
            }else{
                history.push("/upload-records");
            }
        }));
    };
    const { isLoading: isRequestLoading } = useSelector(requestSelector)
    return(
        <div>
            <motion.div className="upload-medical-wrapper" initial='out' animate='in' exit='out' variants={variants}>
                <div className="upload_constant">
                    <div className="upload_img">
                        <img src={YourPrivateInfo_icon_3} className="img-responsive" alt="private info"/>
                    </div>
                    <p className="up_txt">Upload Medical Records</p>
                    <p className="up-help-txt">Enter your code to get started</p>
                    {responseError && <ResponseError error={responseError}/>}
                    <div className="up_input_txt_wrapper">
                        <form onSubmit={() => {onSubmit()}}>
                            <div className="up_txt_box_constant display-flex">
                                <input className='text-box'
                                       value={requestID}
                                       onChange={(e) => { // @ts-ignore
                                           setRequestID(e.target.value); setResponseError('')}}
                                />
                            </div>
                            <div className="recaptcha-form">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="normal"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={onRecaptchaChange}
                                />
                            </div>
                            <div className="up_act_btn">
                                <button type='button' className="app_btn"
                                onClick={() => {onSubmit()}} disabled={requestID?.length < 1}>ENTER</button>
                            </div>
                        </form>
                    </div>
                </div>
            </motion.div>
            {isRequestLoading && <Loader />}
        </div>
    )
}
