import React from 'react';
import smile_icon from 'assets/images/smile_icon.png';
import {motion} from "framer-motion";
import {variants} from "store/types/variants.types";

export const ThankYou = () => {
    return(
        <motion.div className="upload-medical-wrapper" initial='out' animate='in' exit='out' variants={variants}>
            <div className="upload_constant">
                <div className="upload_img">
                    <img src={smile_icon} className="img-responsive" alt="smile icon"/>
                </div>
                <p className="up_txt">Thank You!</p>
                <p className="up-help-txt">Your files have been received. We will review and release the records to the requestor.</p>
            </div>
        </motion.div>
    )
}
